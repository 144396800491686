.suggest-view {
    display: flex;
    flex-direction: column;
    min-width: 72px;
    width: fit-content;
    overflow: hidden;

    margin-top: 4px;
    position: absolute;
    z-index: 100;

    background-color: var(--rmr-color-hover-background);
    border-radius: var(--rmr-radius-border);
    border: var(--rmr-color-table-default-border) 1px solid;
    box-shadow: 0 0 4px 2px hsla(0, 0%, 50%, 0.1);
}

.suggest-view-variant {
    padding: 0 6px;
    color: var(--rmr-color-primary-text);
}

.suggest-view-variant.ignore:not(:first-child) {
    border-top: var(--rmr-color-table-default-border) 1px solid;
}

.suggest-view-variant.ignore {
    font-size: var(--rmr-font-size-1);
    color: var(--rmr-color-active-faded);
}

.suggest-view-variant:hover {
    background-color: hsla(0, 0%, 50%, 0.05);
    cursor: pointer;
}

.spell-error {
    background-color: rgba(255, 133, 133, 0.12);
    border-bottom: rgba(203, 68, 68, 0.8) 2px solid;
}

.spell-error:hover, .spell-error:active, .spell-error:focus-within, .spell-error::target-text {
    background-color: rgba(255, 133, 133, 0.25);
}

.remirror-theme :not(pre) > code {
    background-color: var(--rmr-color-code-background);
    padding: 2px 3px;
    border-radius: var(--rmr-radius-border);
    border: var(--rmr-color-table-default-border) 1px solid;
}

.MuiToggleButton-root {
    padding: 10px !important;
    border-color: var(--rmr-color-active-border) !important;
}

.MuiBox-root {
    background-color: var(--rmr-color-primary) !important;
}

.toolbar-wrapper > div {
    background-color: var(--rmr-color-primary) !important;
}


.MuiToggleButton-primary {
    background-color: var(--rmr-color-active-background) !important;
    color: rgba(0, 0, 0, 0.8) !important
}
.MuiToggleButton-primary.Mui-disabled{
    background-color: var(--rmr-color-disabled-background) !important;
    color: var(--rmr-color-disabled) !important;
    border-color: var(--rmr-color-disabled-border) !important;
}

.Mui-selected {
    background-color: var(--rmr-color-active-primary) !important;
    color: var(--rmr-color-active) !important

}
.Mui-selected:hover {
    background-color: var(--rmr-color-hover-primary) !important;
    color: var(--rmr-color-active) !important

}

.toolbar-wrapper {
    padding: var(--rmr-space-4) var(--rmr-space-5);
    background-color: var(--rmr-color-primary)
}

.remirror-editor-wrapper {
    padding-top: 0;
    height: 100%;
    overflow-y: auto;
}

.remirror-theme .ProseMirror {
    height: 100%;
    padding: var(--rmr-space-5);
    padding-top: var(--rmr-space-4);
    color: var(--rmr-color-text);
    caret-color: var(--rmr-color-text);
}
.remirror-theme {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    --rmr-color-background: #ffffff;
    --rmr-color-border: rgba(0, 0, 0, 0);
    --rmr-color-foreground: #000000;
    --rmr-color-muted: #f1f3f5;
    --rmr-color-primary: hsl(221, 100%, 94%);
    --rmr-color-active-primary: hsl(219, 70%, 50%);
    --rmr-color-primary-text: hsl(219, 70%, 50%);
    --rmr-color-secondary-text: #fff;
    --rmr-color-text: #252103;
    --rmr-color-disabled: hsla(0, 0%, 13%, 0.3);
    --rmr-color-disabled-background: hsla(0, 0%, 100%, 0.2);
    --rmr-color-disabled-border: hsla(0, 0%, 0%, 0.1);
    --rmr-color-active: hsla(0, 0%, 90%, 1);
    --rmr-color-active-background: hsla(0, 0%, 100%, 0.5);
    --rmr-color-active-border: hsla(0, 0%, 0%, 0.2);
    --rmr-color-active-faded: hsla(0, 0%, 13%, 0.9);
    --rmr-color-hover-background: hsla(0, 0%, 100%, 1);
    --rmr-color-hover-border: hsla(0, 0%, 0%, 0.25);
    --rmr-color-hover-foreground: hsla(0, 0%, 0%, 1);
    --rmr-color-hover-muted: hsla(210, 17%, 88%, 1);
    --rmr-color-hover-primary: hsl(219, 70%, 50%);
    --rmr-color-hover-secondary: hsla(72, 55%, 53%, 1);
    --rmr-color-hover-primary-text: #fff;
    --rmr-color-outline: rgba(0, 0, 0, 0);
    --rmr-color-code-background: hsl(0, 0%, 92%);
    --rmr-color-table-default-border: hsla(0, 0%, 80%, 1);
    --rmr-radius-border: 0.2rem;
    --rmr-font-size-default: 20px;
    --rmr-line-height-heading: 1.5em;
    --rmr-line-height-default: 1.5em;

    font-family: var(--rmr-font-family-default);
    line-height: var(--rmr-line-height-default);
    font-weight: var(--rmr-font-weight-default);
}


@media only screen and (max-width: 480px) {
    .remirror-theme {
        --rmr-space-1: 2px;
        --rmr-space-2: 4px;
        --rmr-space-3: 8px;
        --rmr-space-4: 16px;
        --rmr-space-5: 32px;
        --rmr-space-6: 64px;
        --rmr-space-7: 128px;
        --rmr-space-8: 256px;
    }
}

@media (prefers-color-scheme: dark) {
    .remirror-theme {
        background-color: var(--rmr-color-background);
        --rmr-color-background: #18191f;
        --rmr-color-border: rgba(255, 255, 255, 0);
        --rmr-color-foreground: #000000;
        --rmr-color-muted: #f1f3f5;
        --rmr-color-primary: hsl(219, 27%, 45%);
        --rmr-color-primary-text: #7ba2ea;
        --rmr-color-secondary-text: #000000;
        --rmr-color-text: #ffffff;
        --rmr-color-disabled: hsla(0, 0%, 13%, 0.3);
        --rmr-color-disabled-background: hsla(0, 0%, 100%, 0.2);
        --rmr-color-disabled-border: hsla(0, 0%, 0%, 0.25);
        --rmr-color-active: hsla(0, 0%, 90%, 1);
        --rmr-color-active-background: hsla(0, 0%, 100%, 0.6);
        --rmr-color-active-border: hsla(0, 0%, 0%, 0.5);
        --rmr-color-active-primary: hsl(220, 63%, 52%);
        --rmr-color-active-faded: hsla(0, 0%, 87%, 0.9);
        --rmr-color-hover-background: #24252c;
        --rmr-color-hover-border: hsla(0, 0%, 0%, 0.25);
        --rmr-color-hover-foreground: hsla(0, 0%, 0%, 1);
        --rmr-color-hover-muted: hsla(210, 17%, 88%, 1);
        --rmr-color-hover-primary: hsl(219, 70%, 50%);
        --rmr-color-hover-secondary: hsla(72, 55%, 53%, 1);
        --rmr-color-hover-primary-text: #fff;
        --rmr-color-outline: rgba(0, 0, 0, 0);
        --rmr-color-code-background: hsla(0, 0%, 100%, 0.2);
        --rmr-color-table-default-border: hsla(0, 0%, 100%, 0.3);
    }
}